html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  color: #3c3c3c;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 37px !important;
}

.h1 {
  color: #00567a !important;
}

h2 {
  color: #3c3c3c;
  font-size: 18px !important;
  line-height: 20px !important;
}
.h3 {
  color: #231f20;
  font-size: 18px;
  line-height: 22px;
}

.font-size-10 {
  font-size: 10px;
  line-height: 12px;
}

.font-size-16 {
  font-size: 16px !important;
  line-height: 20px !important;
}

.font-size-18 {
  font-size: 18px;
  line-height: 20px;
}

.font-size-20 {
  font-size: 20px;
  line-height: 19px;
}

.font-size-30 {
  font-size: 32px;
  line-height: 30px;
}

.font-size-32 {
  font-size: 30px;
  line-height: 37px;
}

.font-size-36 {
  font-size: 36px;
  line-height: 44px;
}

.font-size-64 {
  font-size: 64px;
  line-height: 65px;
}

.bold,
b {
  font-family: "Frutiger_bold";
}

/* Buttons */
.primary-button {
  cursor: pointer;
  border: 0px;
  background: #00567a;
  border-radius: 500px;
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  height: 46px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  min-width: 160px;
}

.custom-re-register-button {
  line-height: 45px;
  min-width: 178px;
}

.primary-button:hover {
  background-color: #005eb8;
}

.primary-button:disabled {
  background: #8c8c8c;
}

.primary-button svg {
  margin-left: 10px;
}

.primary-button svg path {
  stroke: #ffffff;
}

.second-button {
  background: #00567a;
  border: 2px solid #00567a;
  box-sizing: border-box;
  border-radius: 500px;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  height: 46px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}

.second-button:disabled {
  opacity: 0.6;
}

.second-button:disabled:hover {
  background: #00567a;
}

.second-button:hover {
  background-color: #00567a;
}

.second-button svg {
  margin-left: 10px;
}

.second-button svg path {
  stroke: #ffffff;
}

.selected-register-button {
  background: #bad6ed;
  border: 2px solid #bad6ed;
  box-sizing: border-box;
  border-radius: 500px;
  color: black;
  font-size: 18px;
  line-height: 22px;
  height: 46px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}

.approve-button {
  background: #00a03b;
  border-radius: 500px;
  width: 159px;
  height: 46px;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.btn-paf {
  font-size: 15px !important;
  width: 127px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-paf span {
  margin-left: 15px;
  color: white;
}

.approve-dispense-button {
  background: #0072ce;
  border-radius: 500px;
  width: 127px;
  height: 46px;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.approve-dispense-button span {
  align-self: center;
  color: white;
}

.approve-dispense-button svg {
  width: 55px;
  align-self: center;
}

.revert-approver-button {
  background: #aa198d;
  border-radius: 500px;
  width: 200px !important;
  height: 46px;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.revert-approver-button span {
  align-self: center;
  color: white;
  width: 200px;
}

.revert-approver-button svg {
  width: 55px;
  align-self: center;
}

.green-button {
  background: #00567a;
  border-radius: 500px;
  width: 159px;
  height: 46px;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.green-button:hover {
  background: #005eb8;
}

.red-button {
  background: #da291c;
  border-radius: 500px;
  width: 159px;
  height: 46px;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.red-button:hover {
  background: #d45454;
}

.green-light-button {
  background: #00567a;
  border-radius: 500px;
  height: 46px;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  border: 0;
  font-weight: bold;
}

.purple-button {
  background: #aa198d;
  border-radius: 500px;
  width: 159px;
  height: 46px;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.purple-button:hover {
  background: #aa3b94;
}

.prev {
  font-size: 16px;
  line-height: 19px;
  color: #8c8c8c;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
}

.prev:hover {
  color: #3c3c3c;
}

.prev:disabled {
  color: #8c8c8c;
}

.cancel {
  cursor: pointer;
  border: none;
  background: transparent;
  color: #646464;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.cancel:hover {
  color: #646464c9;
}

.cancel:hover a {
  color: #646464c9;
}

.cancel:hover span {
  color: #646464c9;
}

.cancel:hover svg path {
  fill: #646464c9;
}

/* Modal */
.wrapper-modal {
  display: grid;
  text-align: center;
  padding: 57px;
  min-height: 350px;
}

.wrapper-modal-mfa {
  padding: 25px;
}

.wrapper-modal .main-text {
  color: #3c3c3c;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
}

.wrapper-modal .main-text-orient {
  color: #00567a;
  font-size: 30px;
  line-height: 37px;
}

.wrapper-modal .main-text-error {
  color: #da291c;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 10px;
}
.wrapper-modal .revert-text {
  color: #aa198d;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .ReactModal__Content {
    width: 50% !important;
  }
}

@media screen and (max-width: 900px) {
  .ReactModal__Content {
    width: 80% !important;
  }
}

.wrapper-modal .second-text,
.wrapper-modal u {
  color: #646464;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 0;
}

.wrapper-modal .second-text a {
  color: #646464;
  font-size: 16px;
  line-height: 19px;
}

.wrapper-modal .second-text div {
  font-size: 16px;
  line-height: 19px;
  max-height: 40vh;
  overflow-y: auto;
}

.wrapper-modal .second-text a:hover {
  color: #8c8c8c;
}

.wrapper-modal .image {
  margin-bottom: 10px;
}

.wrapper-modal .min-width-error-message {
  min-height: 40px;
}

.wrapper-buttons {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 45px;
}

.wrapper-buttons-mfa {
  margin-bottom: 15px;
}

.close-icon-button {
  position: absolute;
  right: 20px;
  background: transparent;
  border: 0;
}

.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 4;
}

::placeholder {
  font-size: 18px;
  line-height: 22px;
  color: #c8c8c8;
}

.mr-4 {
  margin-right: 40px;
}

.mr-6 {
  margin-right: 60px;
}
.m-top-3 {
  margin-top: 30px;
}
.m-bot-3 {
  margin-bottom: 30px;
}

.justify {
  text-align: justify;
}

.other-input-reject {
  margin-top: 20px;
}

.other-input-reject .input-rejection {
  width: 100%;
}

.input-rejection-error {
  color: #e41818;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  float: left;
}

.input-search {
  border: 2px solid #eeeeee;
  background: #eeeeee;
  border-radius: 500px;
  height: 46px;
  min-width: 244px;
  color: #00567a;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border: 0;
  font-size: 14px;
  line-height: 17px;
}

.input-search-general {
  border: 2px solid #eeeeee;
  background: #eeeeee;
  border-radius: 500px;
  height: 46px;
  min-width: 244px;
  color: #3c3c3c;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border: 0;
  font-size: 14px;
  line-height: 20px;
}

.input-search:focus-visible {
  border: 0;
  outline-color: transparent;
}

.input-search-general:focus-visible {
  border: 0;
  outline-color: transparent;
}

.input-search::placeholder {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #00567a;
}

.input-search-general::placeholder {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #3c3c3c;
}

.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 14px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid #00567a;
  border-radius: 50%;
}

.container-radio .checkmark.checkmark-red {
  border-color: #e41818;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: white;
  border: 2px solid #00567a;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #00567a;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #00567a;
  margin-top: -2px;
  border-radius: 2px;
}

.custom-checkbox .checkmark-error {
  border-color: #e41818;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #00567a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 6px;
  top: 0px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-radius: 2px;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.green-haze {
  color: #00a03b !important;
}

.green-haze-background:hover {
  background-color: #009639;
}

.green-haze-background {
  background-color: #00a03b;
}

.green-haze-color {
  color: #00a03b !important;
}

.orient {
  color: #00567a !important;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.layout-modal {
  position: relative;
  background: #f8f8f8;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  padding: 58px 100px;
  z-index: 2;
  max-width: 1100px;
}

.layout-modal .close-icon {
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
}

.layout-modal .step {
  color: #00567a;
}

.gray-lightC8-hr {
  border: 1px solid #c8c8c8;
}

.gray-lightE8-hr {
  border: 1px solid #e8edee;
  background: #e8edee;
}

.gray-hr {
  border: 1px solid #768692;
  background: #768692;
}

.min-height {
  min-height: 500px;
}

.custom-select__control,
.custom-select-error__control,
.custom-select-revert__control {
  border-color: transparent !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  outline-color: transparent !important;
  box-shadow: none !important;
  background-color: #f8f8f8 !important;
  border-bottom: 1px solid #c8c8c8 !important;
}

.custom-rejection .custom-select-error__control,
.custom-select-revert__control {
  border-bottom: 0 !important;
  padding-left: 10px;
  padding-right: 10px;
}

.custom-select__single-value,
.custom-select__placeholder,
.custom-select-error__placeholder,
.custom-select-revert__placeholder,
.custom-select__single-value,
.custom-select-error__single-value,
.custom-select-revert__single-value {
  color: #646464 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: left;
}

.custom-select-placeholder .custom-select__placeholder {
  font-size: 17px !important;
}

.custom-select-error__menu-list,
.custom-select-revert__menu-list {
  max-height: 150px !important;
}

.custom-select__value-container,
.custom-select-error__value-container,
.custom-select-revert__value-container {
  padding: 0 !important;
  height: 27px !important;
  align-items: end !important;
  margin-left: -1px !important;
}

.custom-select__menu,
.custom-select-error__menu,
.custom-select-revert__menu {
  box-shadow: none !important;
  width: 100% !important;
}

.custom-select__menu > div > div,
.custom-select-error__menu > div > div,
.custom-select-revert__menu > div > div {
  background-color: white;
  color: #646464;
  font-weight: bold;
  border-bottom: 1px solid #f8f8f8;
  text-align: left;
}

.custom-select__menu > div > div:hover {
  background-color: #768692;
  color: white;
}

.custom-select-error__menu > div > div:hover {
  background-color: #e41818;
  color: white;
}
.custom-select-revert__menu > div > div:hover {
  background-color: #aa198d;
  color: white;
}

.custom-select__menu-list,
.custom-select-error__menu-list {
  padding-top: 0px !important;
  padding-bottom: 0 !important;
}

.custom-select__input-container {
  margin: 0 !important;
}

.custom-ol {
  padding-left: 1rem;
}

.custom-ol li {
  margin-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .layout-modal {
    max-width: 800px;
    padding: 40px 75px;
  }
}

.label-disabled {
  color: #8c8c8c !important;
}

.error-text p {
  font-weight: 300;
  color: #e41818;
  padding: 0px;
  margin: 2px 0;
}

.required-label {
  color: #e41818 !important;
  border-color: #e41818 !important;
}

.required-text {
  color: #e41818;
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  position: absolute;
  bottom: 0px;
  left: 10px;
  font-weight: 300;
}

.unset-bottom-left {
  bottom: unset;
  left: unset;
}

.custom-select-register .required-text {
  margin-bottom: -5px;
}

.paf-renew-custom .required-text {
  bottom: -16px;
}

.required-text-password {
  position: absolute;
  top: 57px;
  left: 10px;
  font-size: 12px;
  line-height: 15px;
}

.required-text-password p {
  color: #e41818;
  margin-bottom: 0;
  margin-top: 0px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}

.required-text-password.green-text p {
  color: #00a03b;
}

.custom-select-register .error-select > div {
  border-bottom: 1px solid #e41818 !important;
}

.custom-select-register .custom-select__control {
  margin-bottom: 10px;
}

.red-label {
  color: #e41818 !important;
}

.blue-label {
  color: #00567a !important;
}

.custom-ul {
  list-style: none;
}

ul.custom-ul li {
  font-weight: bold;
}

.view-patients .bold {
  font-weight: bold;
}

.input-password-modal,
.input-rejection {
  -webkit-box-shadow: 0 0 0 50px #f8f8f8 inset !important;
  box-shadow: 0 0 0 50px #f8f8f8 inset !important;
  outline: transparent;
  border: 0;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.col-sm-6-custom .required-text {
  bottom: -5px;
}

.align-right {
  text-align: right;
}

.padding-20 {
  margin: 0px 60px 0px 60px;
}

@media screen and (max-width: 1200px) {
  .layout-modal {
    max-width: 800px;
    padding: 40px 75px;
  }
}

@media screen and (max-width: 850px) {
  .layout-modal {
    max-width: 600px;
  }

  .primary-button {
    width: 220px;
  }

  .col-sm-6-custom {
    flex: 0 0 auto !important;
    width: 50% !important;
  }

  .col-sm-6-custom-full {
    flex: 0 0 auto !important;
    width: 99% !important;
  }

  h1 {
    font-size: 27px !important;
    line-height: 32px !important;
  }
}

.display-none {
  display: none;
}

.flex-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-justify-without-center-items {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.treated-checkbox {
  color: #646464 !important;
}

.mineShaft-color {
  color: #3c3c3c !important;
}

.doveGray-color {
  color: #646464 !important;
}

.silver {
  color: #8c8c8c !important;
}

.white-background {
  background-color: white !important;
}

.paf-header {
  min-height: 49px;
  margin-bottom: -25px;
  padding: 0px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}

.ml-10 {
  margin-left: 10px;
}

.cancel-btn {
  background: transparent;
  color: #8c8c8c;
}

.view-card-ul li {
  font-size: 18px !important;
  line-height: 20px !important;
}

.color-orient {
  color: #00567a !important;
}

.transparent-background {
  background-color: transparent !important;
}
.brochure-link {
  text-decoration: underline;
  background: transparent;
  cursor: pointer;
}
.brochure-link:hover {
  color: #c8c8c8;
}
.training-modal {
  padding: 3rem 3rem 2rem;
}

.noClick {
  pointer-events: none;
}

.wholesaler-error {
  bottom: -10px;
  left: 0px;
}
/* Style for release notes modal */
.release-notes-wrapper {
  display: flex;
  flex-direction: column;
  margin: 15px auto;
}

.scroll-on-release-notes {
  text-align: left;
  overflow-y: auto;
  max-height: 250px;
  padding: 0px 0px 10px 0px !important;
  margin-top: 15px !important;
}

.mb-10-px {
  margin-bottom: 10px;
}

/* AsyncPaginate Start */
.optimization-dropdown > div {
  background-color: transparent !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0px;
  outline-color: transparent;
  outline: none;
  box-shadow: 0 0 0 1px transparent !important;
  border-color: #c8c8c8;
  margin-top: -18px;
}
.optimization-dropdown > div:hover {
  border-color: #c8c8c8 !important;
  box-shadow: 0 0 0 1px transparent !important;
}
.optimization-dropdown > div:nth-of-type(2) {
  background-color: #ffffff !important;
  margin-top: 0px;
}
.optimization-dropdown > div:nth-of-type(2) > div > div {
  background-color: #ffffff;
}
.optimization-dropdown > div:nth-of-type(2) > div > div:hover {
  background-color: #768692;
}

.optimization-dropdown
  > div:nth-of-type(2)
  > div
  > div:hover
  > div
  > div
  > div {
  color: white !important;
}
.optimization-dropdown > div:nth-of-type(2) > div > div:active {
  background-color: #768692;
  color: white !important;
}
.optimization-dropdown
  > div:first-of-type
  > div:nth-of-type(2)
  > div:first-of-type {
  display: none !important;
}
.optimization-dropdown > div > div {
  padding: 0 !important;
}
.optimization-dropdown > div:first-of-type > div > div > div > div > div {
  font-size: 18px;
  line-height: 22px;
}

.optimization-dropdown > div:nth-of-type(1) > div > div {
  font-size: 18px;
  line-height: 22px;
}

.resend-email {
  margin-top: 10px;
}

.resend-email-p {
  cursor: pointer;
  color: #00567a;
}
.resend-email:hover {
  text-decoration: underline;
  color: #00567a;
}

.container-medication {
  height: 70px;
}
.message {
  margin: 0px !important;
}
/* AsyncPaginate End */
.col2 {
  display: flex !important;
  flex-direction: column !important;
}
.colm {
  height: 100px;
}
.align-self {
  align-self: center;
}

@media screen and (min-width: 601px) {
  .noWrap {
    white-space: nowrap;
  }
}

.flex {
  display: flex;
}
