.container--div {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 20px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  width: 245px;
}

.react-calendar-div {
  position: absolute;
  z-index: 100;
  width: 300px !important;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2));
  display: block !important;
  margin-top: 15px;
}

.react-calendar-div input.rs-picker-toggle-textbox {
  margin-top: 0px !important;
}

.react-calendar-div input.rs-picker-toggle-textbox::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 4;
}

.react-arrow-div {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent white transparent;
  z-index: 1000;
}

.react-calendar-div hr {
  border: 1px solid #f8f8f8;
  margin: 0;
  opacity: 80%;
}

.react-calendar-div input {
  margin-top: 10px !important;
  border: 0 !important;
  font-size: 14px !important;
}

.calendar-input-div {
  display: flex;
  justify-content: space-around;
  width: 300px !important;
  background-color: white;
  padding-top: 7px;
  height: 27px;
}

.calendar-input-div img {
  margin: 0px !important;
}

.react-calendar
  .react-calendar--selectRange
  .react-calendar__month-view
  .react-calendar__navigation {
  width: 300px !important;
}

.react-calendar__month-view__weekdays {
  width: 300px !important;
  flex-direction: row !important;
}

.react-calendar__viewContainer {
  width: 300px !important;
  height: 300px !important;
}

.calendar-input-div button {
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  border: none;
  background-color: transparent;
  padding-bottom: 10px;
}

.calendar-input-div input {
  width: 100px;
  border: 0;
  text-align: center;
}

.react-calendar {
  width: 300px !important;
  border: none;
  display: flex;
  flex-direction: column;
}

.react-calendar--selectRange {
  display: flex;
  flex-direction: column;
}

.react-calendar__month-view {
  width: 300px !important;
  height: 300px !important;
}

.react-calendar__month-view div {
  display: flex;
  flex-direction: column;
  width: 300px !important;
}

.react-calendar__month-view__days {
  display: flex;
  height: 260px;
  width: 300px !important;
  flex-wrap: wrap;
  flex-direction: row !important;
}

.react-calendar abbr {
  color: black;
  font-size: 12px !important;
}

.react-calendar abbr[title] {
  text-decoration: none;
}

.react-calendar__navigation {
  margin-bottom: 0px !important;
  width: 300px !important;
  justify-content: center;
}

.react-calendar__year-view {
  width: 300px !important;
  background-color: none !important;
}
.react-calendar__year-view__months {
  display: flex;
  flex-wrap: wrap;
  width: 300px !important;
}
.react-calendar__decade-view {
  width: 300px !important;
  background-color: none !important;
}
.react-calendar__decade-view__years {
  display: flex;
  flex-wrap: wrap;
  width: 300px !important;
}
.react-calendar__century-view {
  width: 300px !important;
  background-color: none !important;
}
.react-calendar__century-view__decades {
  display: flex;
  flex-wrap: wrap;
  width: 300px !important;
}

.react-calendar .react-calendar--selectRange {
  display: flex;
  flex-direction: column;
}

.react-calendar__year-view__months button {
  background-color: white !important;
}
.react-calendar__decade-view__years button {
  background-color: white !important;
}
.react-calendar__century-view__decades button {
  background-color: white !important;
}

.react-calendar__tile--active {
  background-color: rgba(0, 86, 122, 0.5) !important;
  border-radius: 0% !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: rgba(0, 86, 122, 0.5) !important;
  border-radius: 0% !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: rgba(0, 86, 122, 0.5) !important;
  border-radius: 0% !important;
}

.react-calendar__tile:enabled:hover {
  background-color: rgba(0, 86, 122, 0.5) !important;
  border-radius: 0% !important;
}

.react-calendar__tile--now {
  background: #f0f0f0;
  border-radius: 500px;
  width: 20px;
}
.rs-btn-subtle > svg {
  pointer-events: none;
}
