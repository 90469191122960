@font-face {
  font-family: "Frutiger";
  src: url(./assets/fonts/Frutiger.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Frutiger_bold";
  src: url(./assets/fonts/Frutiger_bold.ttf);
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Frutiger", sans-serif !important;
}

* {
  -webkit-text-size-adjust: none;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

#root {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #8c8c8c
}
